/* You can add global styles to this file, and also import other style files */

/*input.form-control, button.btn {
  border-radius: 0;
}

button.btn-primary {
  background-color: #3371e7;
  color: white;
  border-color: whitesmoke;
}

button.btn-primary:disabled {
  background-color: #333333;
  color: lightgray;
  border-color: whitesmoke;
}

button.btn-primary:hover {
  background-color: rgba(19, 59, 185, 0.66);
  border-color: whitesmoke;
  box-shadow: none;
}

button.btn-primary:focus, button.btn-primary:focus-within {
  background-color: rgba(19, 59, 185, 0.66);
  border-color: whitesmoke;
  box-shadow: none;
}

span.input-group-text {
  border-radius: 0;
}

input.form-check-input:checked {
  background-color: rgba(19, 59, 185, 0.66);
  border-color: rgba(19, 59, 185, 0.66);
}

div.ck-content, div.ck-source-editing-area textarea {
  height: 300px;
  overflow-y: auto;
}*/

/* Firefox */
html {
  scrollbar-color: rgba(13, 110, 253, 0.6) white;
  scrollbar-width: auto;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 16px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background: rgba(13, 110, 253, 0.6);
  border-radius: 5px;
}

.drop-zone {
  border: 3px dashed #2370f6;
  border-radius: 5px;
  padding: 5px;
}

.content-zone {
  font-style: normal;
  font-size: 24px;
  line-height: 130%;
  font-weight: bold;

  background-color: #e5f1f6;
  height: 140px;
  vertical-align: middle;
  text-align: center;


}

.inner-block {
  padding-top: 48px;

  a.link {
    color: black;
    text-decoration: underline;
    font-weight: bold;
  }
}


.ngx-file-drop__drop-zone--over {

  .content-zone {
    background-color: #2370f6;
    color: white;
  }
}
